// INPUT CHANGES
export const CHANGE_MONETARY_INPUT = "CHANGE_MONETARY_INPUT";

export const CHANGE_SELECTED_INPUT_CURRENCY_CODE =
  "CHANGE_SELECTED_INPUT_CURRENCY_CODE";
export const CHANGE_SELECTED_OUTPUT_CURRENCY_CODE =
  "CHANGE_SELECTED_OUTPUT_CURRENCY_CODE";

export const OPEN_INPUT_CURRENCY_CODE_SELECTOR =
  "OPEN_INPUT_CURRENCY_CODE_SELECTOR";
export const CLOSE_INPUT_CURRENCY_CODE_SELECTOR =
  "CLOSE_INPUT_CURRENCY_CODE_SELECTOR";
export const OPEN_OUTPUT_CURRENCY_CODE_SELECTOR = "OPEN_CURRENCY_CODE_SELECTOR";
export const CLOSE_OUTPUT_CURRENCY_CODE_SELECTOR =
  "CLOSE_CURRENCY_CODE_SELECTOR";

export const FETCH_LATEST_EXCHANGE_RATES = "FETCH_LATEST_EXCHANGE_RATES";
export const FETCH_LATEST_EXCHANGE_RATES_FAILED =
  "FETCH_LATEST_EXCHANGE_RATES_FAILED";
export const FETCH_LATEST_EXCHANGE_RATES_SUCCESS =
  "FETCH_LATEST_EXCHANGE_RATES_SUCCESS";

export const ADD_CHARACTER_AT_END = "ADD_CHARACTER_AT_END";
export const CLEAR_INPUT = "CLEAR_INPUT";

export const CHANGE_INPUT_CURRENCY_ON_CLICK = "CHANGE_INPUT_CURRENCY_ON_CLICK";
