import React from "react";

export const ExchangeRateIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 398.923 398.923"
    className={className}
  >
    <g>
      <path d="M398.425,131.075c-0.826-2.52-2.621-4.609-4.986-5.806l-8.923-4.516c-2.366-1.197-5.112-1.407-7.632-0.58   c-2.52,0.826-4.609,2.62-5.806,4.986l-11.255,22.239c-6.629-22.075-17.601-42.652-32.415-60.567   c-21.099-25.516-48.973-44.391-80.607-54.585c-17.4-5.608-35.377-8.451-53.43-8.451c-27.576,0-54.155,6.456-78.999,19.19   c-41.265,21.15-71.823,57.103-86.047,101.237c-1.694,5.256,1.194,10.891,6.451,12.585l9.518,3.067   c5.259,1.696,10.892-1.194,12.585-6.451c11.766-36.506,37.044-66.247,71.178-83.741c20.563-10.539,42.542-15.883,65.327-15.883   c14.927,0,29.805,2.355,44.217,7c47.243,15.225,82.96,53.828,95.083,101.102l-28.955-14.653   c-4.926-2.496-10.943-0.521-13.438,4.407l-4.516,8.923c-1.198,2.367-1.406,5.112-0.58,7.632c0.826,2.52,2.62,4.609,4.987,5.806   l62.565,31.661c1.447,0.733,2.988,1.08,4.507,1.08c3.65,0,7.169-2.006,8.93-5.486l31.662-62.564   C399.043,136.34,399.251,133.595,398.425,131.075z" />
      <path d="M364.148,242.116l-9.518-3.067c-5.257-1.695-10.891,1.194-12.585,6.451c-11.766,36.506-37.043,66.247-71.177,83.741   c-20.563,10.539-42.543,15.882-65.329,15.882c-14.928,0-29.804-2.354-44.215-6.999c-47.243-15.225-82.959-53.828-95.083-101.102   l28.955,14.653c4.927,2.496,10.943,0.521,13.438-4.407l4.516-8.923c1.198-2.367,1.406-5.112,0.58-7.632   c-0.827-2.52-2.62-4.609-4.987-5.807l-62.565-31.66c-4.925-2.495-10.943-0.521-13.438,4.407L1.078,260.216   c-1.198,2.367-1.406,5.112-0.58,7.632c0.826,2.52,2.62,4.609,4.986,5.806l8.923,4.516c4.928,2.495,10.944,0.521,13.438-4.407   L39.1,251.524c6.629,22.076,17.601,42.652,32.415,60.567c21.099,25.516,48.972,44.391,80.606,54.585   c17.4,5.608,35.376,8.451,53.429,8.451c27.188,0,54.506-6.636,79-19.19c41.265-21.15,71.823-57.103,86.047-101.237   c0.814-2.524,0.591-5.268-0.619-7.628C368.77,244.712,366.672,242.93,364.148,242.116z" />
      <path d="M200.753,238.729c-9.333,0-19.42-4.033-25.101-10.035l-4.468-4.721c-1.137-1.201-3.036-1.256-4.241-0.117l-16.706,15.81   c-0.577,0.548-0.914,1.302-0.937,2.097c-0.021,0.795,0.273,1.566,0.82,2.145l4.469,4.722c7.706,8.141,18.909,14.354,31.031,17.265   v16.568c0,1.656,1.343,3,3,3h23c1.657,0,3-1.344,3-3V266.48c21.662-4.13,34.999-18.093,34.999-36.887   c0.245-29.6-25.542-37.409-42.605-42.577c-20.501-6.209-23.598-9.316-23.598-16.775c0-7.931,13.087-10.047,20.82-10.047   c7.346,0,16.425,2.421,21.588,5.756l5.46,3.527c1.393,0.898,3.249,0.498,4.147-0.893l12.479-19.318   c0.9-1.393,0.5-3.249-0.892-4.148l-5.459-3.527c-7.256-4.688-16.952-8.128-26.941-9.602v-15.527c0-1.656-1.343-3-3-3h-23   c-1.657,0-3,1.344-3,3v17.115c-19.313,5.345-31.203,19.221-31.203,36.664c0,31.145,27.662,39.523,44.191,44.53   c19.329,5.854,22.064,8.39,22.011,14.702C220.621,236.78,208.132,238.729,200.753,238.729z" />
    </g>
  </svg>
);
